<template>
  <div>
    <Field
      v-bind="$attrs"
      class="textbox"
      :class="theme && `textbox--${theme}`"
      :value="modelValue"
      :name="name"
      @input="$emit('input', $event.target.value)"
    />
    <ErrorMessage :name="name" class="field__error" />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    theme: {
      type: String,
      default: "default",
    },
    name: {
      type: String,
      default: null,
    },
  },
  emits: ["input"],
};
</script>

<style>
.textbox {
  outline: none !important;
  @apply w-full h-9 text-sm dark:bg-gray-850  dark:border-none dark:border-gray-700;
}

.textbox--no-arrow {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.textbox--default {
  @apply bg-gray-100 border-0 rounded px-3;
  &:hover,
  &:focus {
    @apply ring-2 ring-primary-200 ring-inset;
  }
}
</style>
